@use 'mixins' as *;

.safety-class-table {
  padding-block-start: var(--spacing-medium);

  @include breakpoint-min(small) {
    padding-block-start: calc(var(--spacing-medium) + var(--spacing-small));
  }
}

.safety-class {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  p {
    margin: 0;
    margin-block-end: var(--spacing-small);
    width: 48px;
    text-align: center;
  }
}

.table-row {
  width: 100%;
  border-bottom: 1px solid var(--color-gray4);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:last-of-type {
    border-bottom: none;
  }
  p {
    font-size: var(--font-size-body-s);
    line-height: 18px;
    margin: var(--spacing-small);
  }
}

.dots-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dot {
  opacity: 0.2;
  visibility: visible;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 0 20px;

  &.active {
    opacity: 1;
  }

  &.hidden {
    visibility: hidden;
  }
}
