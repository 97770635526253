@use 'mixins' as *;

.container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-gray-4);
  padding: var(--spacing-medium);
  margin-inline: auto;
  max-width: 1200px;

  @include breakpoint-min(medium) {
    flex-direction: row;
    justify-content: space-between;
    padding-block-start: var(--spacing-large);
    gap: 50px;
  }
}

.table-header {
  display: flex;
  flex-direction: column;
}

.icon-wrapper {
  display: flex;
  block-size: 48px;

  > div {
    margin-inline-end: var(--spacing-xxsmall);
  }
}

.text-content {
  display: flex;
  flex-direction: column;
  max-inline-size: 600px;

  h2 {
    font-style: normal;
    font-family: var(--font-helvetica-neue-bold);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-header-3-content);
    line-height: var(--line-height-header-3-content);
    margin: var(--spacing-small) 0;
  }
}

.text-link {
  margin-top: var(--spacing-regular);
  font-size: var(--font-size-body);
  line-height: var(--line-height-body-xs);
  color: var(--color-solid-black);

  &:hover &::after {
    transition: all 0.3s ease;
    transform: translateX(4px);
  }
}
