@use 'mixins' as *;

.text-block {
  display: flex;
  margin: var(--spacing-small) auto;
  padding: var(--text-block-padding, 0 16px);

  &__smaller-text {
    h3 {
      font-style: normal;
      font-weight: var(--font-weight-medium);
      font-family: var(--font-regular-bold);
      font-size: var(--font-size-body);
      line-height: var(--line-height-body);
      margin: 0 0 var(--spacing-xsmall) 0;
    }

    p,
    span,
    a {
      text-decoration: none;
      font-style: normal;
      font-weight: var(--font-weight-regular);
      font-size: var(--font-size-body);
      line-height: var(--line-height-body);
      margin: 0;
    }

    a {
      display: block;

      &:hover {
        color: var(--color-solid-black-hover);
      }
    }
  }

  @include breakpoint-min(small) {
    margin: var(--spacing-regular) 0;
  }
}

.grid {
  inline-size: 100%;
}

.h-tag {
  font-family: var(--font-hero);
  font-size: var(--font-size-header-4);
  line-height: var(--line-height-header-4);
  font-weight: var(--font-weight-regular);
  margin: var(--spacing-small) 0;
  text-transform: uppercase;
}

.markdown {
  &__center {
    text-align: center;
  }

  p:last-child {
    margin-block-end: 0;
  }
}
