@use 'mixins' as *;

.cta-link {
  display: flex;
  align-items: center;
  font-size: var(--font-size-body);
  padding-block: var(--spacing-small);
  inline-size: fit-content;

  @include hover {
    color: var(--color-solid-black-hover);
  }

  &__center {
    margin-inline: auto;
  }

  svg {
    margin-inline-end: var(--spacing-xsmall);
  }
}
