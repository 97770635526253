.footer {
  --footer-bg-color: var(--color-solid-black, #111111);
  --footer-font-family-heading: var(--font-hero);
  --footer-font-family-regular: var(--font-family);
  --footer-font-weight-bold: var(--font-weight-medium, 500);
  --footer-font-weight-regular: var(--font-weight-regular, 400);
  --footer-heading-font-size: var(--font-size-h5, 1.125rem);
  --footer-heading-line-height: var(--line-height-header-4, 28px);
  --footer-logo-color: var(--color-white, #fff);
  --footer-logo-size: var(--spacing-medium, 48px);
  --footer-spacing-l: var(--spacing-medium, 48px);
  --footer-spacing-small: var(--spacing-small, 16px);
  --footer-text-color-hover: var(--color-gray1, #8d8d8d);
  --footer-text-color: var(--color-white, #fff);
}

.logo {
  inline-size: 170px;
  position: relative;
  height: 26px;

  svg {
    inline-size: 100%;
    block-size: 100%;
  }
}
